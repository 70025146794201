import React, { FC } from 'react';
import {Spin} from 'antd';
import Link from 'next/link';
import { UrlObject } from 'url';
import { ButtonWrapper } from './Button.style';
import { IDigandoStylingButtonProps } from './Button';

export interface IDigandoLinkButtonProps extends IDigandoStylingButtonProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
  href: UrlObject;
}

export const LinkButton: FC<IDigandoLinkButtonProps> = (props) => {
  const {
    onClick,
    onFocus,
    spacing = 'default',
    ghost = false,
    small = false,
    bordered = false,
    loading = false,
  } = props;

  return (
    <ButtonWrapper
      spacing={spacing}
      className={`button-container ${ghost ? 'ghost ' : ''}${small ? ' small ' : ''}${bordered ? ' bordered ' : ''} ${loading ? ' loading' : ''}`}
    >
      <Link
        className={'button'}
        onClick={onClick}
        onFocus={onFocus}
        href={props.href}
      >
        <span className={'button-text'}>
          {props.children}
        </span>
        <span className={'button-loader'}>
          <Spin size={'default'} />
        </span>
      </Link>
    </ButtonWrapper>
  );
};

